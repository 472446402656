<template>
  <section>
    <Toast />
    <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="lg:flex">
        <div class="flex gap-2 items-center w-full lg:w-6/12">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de Apartados</p>
        </div>
        <div v-if="$can('pharmasan.ventas.ov.crear')" class="w-full lg:w-6/12 flex lg:justify-end">
          <Button @click="abrirModalAbrirApartado" size="small" label="Crear apartado" class="w-full lg:w-max" icon="pi pi-plus" />
        </div>
      </div>
      <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
        <div class="w-full">
          <label class="text-sm text-gray-600" for="numero_orden">Número de orden</label>
          <InputText class="w-full " id="numero_orden" @keyup.enter="agregarApart" v-model="apartadosSeleccionados" @keydown="validacionApartados($event)" />
          <div class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-auto">
            <div v-for="(orden, i) in apartadosFiltrados" :key="i" class="">
              <div class="flex items-center p-1 bg-blue-200 text-blue-600 rounded-md">
                <p class="text-sm">{{ orden }}</p>
                <button @click="eliminarApart(orden)" class="mx-1"><i class="pi pi-times text-sm"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full">
          <label class="text-sm text-gray-600" for="fecha_inicio">Fecha inicio</label>
          <InputText
            id="fecha_inicio"
            type="date"
            class="w-full "
            v-model="filtros.CreatedAt[0]"
            :pt="{
                root: { style: 'height: 37px' }
            }"
          />
        </div>
        <div class="w-full">
          <label class="text-sm text-gray-600" for="fecha_fin">Fecha fin</label>
          <InputText
            id="fecha_fin"
            type="date"
            class="w-full "
            v-model="filtros.CreatedAt[1]"
            :pt="{
                root: { style: 'height: 37px' }
            }"
          />
        </div>
        <div class="w-full">
          <label class="text-sm text-gray-600" for="bodega">Bodega</label>
          <AutoComplete
          disabled
            id="bodega"
            class="w-full"
            inputClass="w-full"
            v-model="filtros.U_PHR_Bodega"
            :suggestions="bodegasFiltradas"
            @complete="buscarBodega($event)"
            field="name"
          />
        </div>
        <div class="flex items-center mt-3 gap-2">
          <Button
            @click="listar"
            size="small"
            class="rounded-md w-full"
            label="Buscar"
            icon="pi pi-search"
            :pt="{
              root: { style: 'height: 2.4rem' }
            }"
          />
          <Button
            @click="limpiarFiltros"
            size="small"
            severity="secondary"
            class="rounded-md w-full"
            label="Limpiar"
            :pt="{
              root: { style: 'height: 2.4rem' }
            }"
          />
        </div>
      </div>
      <div class="w-full my-6">
        <DataTable class="p-datatable-sm text-sm" :value="apartados.data" responsiveLayout="scroll" selectionMode="single">
          <Column class="w-32" header="No. Orden">
            <template #body="{data}">
              <div class="text-start pt-3">
                <p class="text-blue-600">{{ data.OvId }}</p>
                <div class="flex gap-x-4">
                  <button @click="editar(data.Id)" class="underline italic idHijo text-white transition ease-in-out duration-500">Editar</button>
                  <button @click="eliminar(data.Id, data.ItemName, data.OvId)" class="underline italic idHijoEliminar text-white transition ease-in-out duration-500">Eliminar</button>
                </div>
              </div>
            </template>
          </Column>
          <Column class="w-32" field="CreatedAt" header="Fecha Creación">
            <template #body="{data}">
                {{ dayjs(data.CreatedAt).format('YYYY-MM-DD') }}
            </template>
          </Column>
          <Column class="w-56" field="CodBod" header="Bodega">
            <template #body="{data}">
              {{ data.CodBod + ' - ' + data.WhsName }}
            </template>
          </Column>
          <Column style="width: 25rem;" field="ItemCode" header="Medicamento">
            <template #body="{data}">
              {{ data.ItemCode + ' - ' + data.ItemName }}
            </template>
          </Column>
          <Column field="Cantidad" header="Cantidad"></Column>
          <Column field="fullName" header="Creado por">
          </Column>
          <!-- <Column header="Acción">
            <template #body="{data}">
              <div class="flex gap-4">
                <button @click="editar(data.Id)"><i class="pi pi-pencil text-blue-600"></i></button>
                <button @click="eliminar(data.Id, data.ItemName, data.OvId)"><i class="pi pi-trash text-red-600"></i></button>
              </div>
            </template>
          </Column> -->
        </DataTable>
        <Paginator v-model:first="offset"
          :rows="limit"
          :totalRecords="parseInt(apartados.count)"
          :rowsPerPageOptions="[2,10,20,30,100]"
          @page="onPage($event)"
        />
      </div>
    </div>
    <Dialog :closable="false" v-model:visible="displayCrearApartado" :header="modelApartado.Id ? 'Actualizar apartado' : 'Crear Apartado'" :style="{width: '30vw'}" :modal="true">
      <div class="w-full">
        <div class="w-full flex flex-wrap text-sm items-center gap-2">
          <div class="w-full">
            <p class="font-medium">Medicamento en stock:</p>
          </div>
          <div class="w-full">
            <AutoComplete
              v-model="medicamentoSeleccionadoDeEntrada"
              :suggestions="medicamentosDeEntrada"
              @complete="buscarMedicamentosEnStock"
              @item-select="setMedicamentoEntradaMercancia"
              optionLabel="ItemName"
              field="ItemName"
              class="w-full"
              inputClass="w-full"
            >
              <template #item="{ item }">
                <div style="text-wrap: wrap">
                  <span>{{item.CodMx + ' - ' + item.ItemName + ' - Cant. Stock: ' + parseInt(item.cantstock)}}</span>
                </div>
              </template>
            </AutoComplete>
            <div class="w-full flex gap-2 text-xs" v-if="medicamentoSeleccionadoDeEntrada">
              <p class="italic">Cantidad en Stock:</p>
              <p class="italic text-red-600">{{ medicamentoSeleccionadoDeEntrada.cantstock }}</p>
              <p class="italic">Bodega:</p>
              <p class="italic text-red-600">{{ medicamentoSeleccionadoDeEntrada.WhsCode }}</p>
            </div>
<!--            <Dropdown-->
<!--              v-model="medicamentoSeleccionadoDeEntrada"-->
<!--              :options="medicamentosDeEntrada"-->
<!--              :filter="true"-->
<!--              panelClass="text-sm w-20"-->
<!--              class="text-sm w-full rounded-md border-gray-300"-->
<!--              optionLabel="ItemName"-->
<!--              inputClass="text-sm"-->
<!--              id="medicamentos"-->
<!--              @change="setMedicamentoEntradaMercancia"-->
<!--            >-->
<!--              <template #option="{ option }">-->
<!--                <div style="text-wrap: wrap;">-->
<!--                  <span>{{option.CodMx + ' - ' + option.ItemName + ' - Cant. Stock: ' + parseInt(option.cantstock)}}</span>-->
<!--                </div>-->
<!--              </template>-->
<!--            </Dropdown>-->
          </div>
          <div class="w-full">
            <p class="font-medium">Orden Venta:</p>
          </div>
          <div class="w-full">
            <div class="flex gap-2">
              <InputNumber size="small" inputId="withoutgrouping" @change="getOvs(modelApartado.OvId)" @keyup.enter="getOvs(modelApartado.OvId)" v-model="modelApartado.OvId" :useGrouping="false" class="w-full p-1" />
              <Button @click="getOvs(modelApartado.OvId)" icon="pi pi-search" class="p-0 h-min" text />
            </div>
          </div>
          <div class="w-full">
            <p class="font-medium">Medicamento:</p>
          </div>
          <div class="w-full">
            <Dropdown
              v-model="medicamentoSeleccionado"
              :options="medicamentos"
              :filter="true"
              @change="setMedicamentoSeleccionado"
              panelClass="text-sm w-20"
              class="text-sm w-full rounded-md border-gray-300"
              optionLabel="ItemName"
              inputClass="text-sm"
              id="medicamentos"
              :emptyMessage="modelApartado.OvId ? 'No existen pendientes' : 'Debes ingresar una orden primero primero'"
            >
              <template #option="{ option }">
                <div style="text-wrap: wrap;">
                  <span>{{option.ItemCode + ' - ' + option.ItemName}}</span>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="w-full">
            <p class="font-medium">Cantidad a apartar:</p>
          </div>
          <div class="gap-2 w-full">
            <div class="flex">
              <InputNumber
                size="small"
                v-model="modelApartado.Cantidad"
                :min="0"
                :max="parseInt(medicamentoSeleccionado ? medicamentoSeleccionado.OpenCreQty : 0) > parseInt(medicamentoSeleccionadoDeEntrada ? medicamentoSeleccionadoDeEntrada.Quantity : 0) ? parseInt(medicamentoSeleccionadoDeEntrada ? medicamentoSeleccionadoDeEntrada.Quantity : 0) : parseInt(medicamentoSeleccionado ? medicamentoSeleccionado.OpenCreQty : 0)"
                :useGrouping="false"
                class="w-full p-1"
              />
              <Button
                @click="agregarPreliminar" icon="pi pi-plus"
                v-if="!modelApartado.Id"
              />
            </div>
            <div v-if="medicamentoSeleccionado && medicamentoSeleccionado.OpenCreQty" class="w-full flex gap-2 text-xs">
              <p class="italic">Cantidad pendiente:</p>
              <p class="italic text-red-600">{{ medicamentoSeleccionado.OpenCreQty }}</p>
              <p class="italic">Cantidad maxima por apartar:</p>
              <p class="italic text-red-600">{{ medicamentoSeleccionadoDeEntrada.cantstock }}</p>
            </div>
          </div>
          <div class="gap-2 w-full">
            <div v-for="(medicamento, i) in pendientesPorApartar" :key="i" class="flex text-xs italic mt-2 mb-2 items-center">
              <span>
                <span class="font-bold">Ov: </span>{{ medicamento.OvId }}
                <span class="font-bold"> ItemCode: </span>{{ medicamento.ItemCode}}
                <span class="font-bold"> Cantidad: </span>{{ parseInt(medicamento.Cantidad) }}
              </span>
              <button @click="pendientesPorApartar.splice(i, 1)" class="mx-1 text-red-600"><i class="pi pi-trash text-sm"></i></button>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button @click="crearApartado" label="Guardar" v-if="!modelApartado.Id"/>
        <Button @click="actualizarApartado" label="Actualizar" v-else-if="modelApartado.Id"/>
        <Button class="p-button-outlined p-button-secondary" @click="cerrarModal" label="No, Cancelar" />
      </template>
    </Dialog>
  </section>
</template>
<script setup>
import dayjs from 'dayjs'
import ApartadoService from '../../../services/apartados.service'
import { onMounted, ref, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'
  // Servicios
const _ApartadoService = ref(new ApartadoService())
// Referencias
const apartados = ref([])
const pendientesPorApartar = ref([])
const bodegasFiltradas = ref([])
const apartadosFiltrados = ref([])
const apartadosSeleccionados = ref()
const medicamentoSeleccionado = ref({})
const medicamentosDeEntrada = ref([])
const medicamentoSeleccionadoDeEntrada = ref(null)
const displayCrearApartado = ref(false)
const entradasMercancia = ref([])
const toast = useToast()
const creandoOrden = ref(false)
const modelApartado = ref({
  OvId: null,
  OvDtId: null,
  Cantidad: 0,
  ItemCode: '',
  IdItemCode: 0,
  CodBod: ''
 })
const medicamentos = ref([])
const limit = ref(10)
const offset = ref(0)
const filtros = ref({
  CardCode: null,
  CreatedAt: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
  U_PHR_Bodega: null,
  U_PHR_NumDcto: null,
  DocStatus: null
})
const params = computed(() => {
  return {
    limit: limit.value,
    offset: offset.value,
    ...filtros.value
  }
})
// Metodos
const onPage = ({ first, page, pageCount, rows }) => {
  limit.value = rows
  listar(params.value)
}
const listar = () => {
  agregarApart()
  apartadosFiltrados.value.length ? filtros.value.ovs = JSON.stringify(apartadosFiltrados.value) : filtros.value.ovs = null
  const object = {
    ...params.value
  }
  _ApartadoService.value.listar(object).then(({ data }) => {
    apartados.value = data
  })
}
const agregarApart = () => {
  if (apartadosSeleccionados.value) {
    for (const i of apartadosSeleccionados.value.split(' ')) {
      if (apartadosFiltrados.value && apartadosFiltrados.value.length) {
        if (!apartadosFiltrados.value.some(a => a === i)) {
          i !== '' ? apartadosFiltrados.value.push(i) : apartadosFiltrados.value.push()
        }
      } else {
        if (i !== '') {
          i !== '' ? apartadosFiltrados.value.push(i) : apartadosFiltrados.value.push()
        }
      }
    }
  }
  apartadosSeleccionados.value = ''
}
const validacionApartados = (e) => {
  if (/[^0-9\s]/.test(e.key)) {
    if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
      if (e.key === 'v' && !e.ctrlKey) {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }
  }
}
const eliminarApart = (numeroOv) => {
  const index = apartadosFiltrados.value.findIndex(a => a === numeroOv)
  apartadosFiltrados.value.splice(index, 1)
}
const limpiarFiltros = () => {
  filtros.value = {
    CardCode: null,
    fecha_inicio: dayjs().format('YYYY-MM-DD'),
    fecha_fin: dayjs().format('YYYY-MM-DD'),
    Id: null,
    U_PHR_Bodega: null,
    U_ACS_TpoIdentf: null,
    U_PHR_NumDcto: null,
    U_PHR_ModContrato: null
  }
  apartadosFiltrados.value = []
  apartadosSeleccionados.value = ''
  listar()
}
const cerrarModal = () => {
  modelApartado.value = {
    OvId: null,
    OvDtId: null,
    Cantidad: 0,
    ItemCode: '',
    IdItemCode: 0,
    CodBod: ''
  }
  displayCrearApartado.value = false
  medicamentoSeleccionado.value = {}
}
const crearApartado = () => {
  creandoOrden.value = true
  if (!pendientesPorApartar.value.length) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debes ingresar al menos un medicamento para continuar', life: 3000 })
  for (const pendiente of Object.values(pendientesPorApartar.value)) {
    delete pendiente.AgrupId
    for (const i in pendiente) {
      if (!pendiente[i]) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debes ingresar todos los campos para continuar', life: 3000 })
    }
  }
  _ApartadoService.value.crear(pendientesPorApartar.value).then(({ data }) => {
    if (data) {
      Swal.fire(
        'Guardado!',
        'Apartado asignado con éxito',
        'success'
      ).then(() => {
        creandoOrden.value = false
        cerrarModal()
        listar()
      })
    }
  })
}
const actualizarApartado = () => {
  creandoOrden.value = false
  console.log('modelApartado.value', modelApartado.value)
  for (const i in modelApartado.value) {
    if (!modelApartado.value[i]) {
      return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debes ingresar todos los campos para continuar', life: 3000 })
    }
  }
  _ApartadoService.value.actualizar(modelApartado.value, modelApartado.value.Id).then(({ data }) => {
    if (data) {
      creandoOrden.value = false
      cerrarModal()
      listar()
      Swal.fire(
        'Guardado!',
        'Apartado actualizado con éxito',
        'success'
      )
    }
  })
}
const getOvs = (val) => {
  if (val) {
    _ApartadoService.value.getOv(val).then(({ data }) => {
      if (data) {
        medicamentos.value = data
        medicamentoSeleccionado.value = {}
        document.getElementById('medicamentos').click()
      }
    })
  }
}
const setMedicamentoSeleccionado = () => {
  modelApartado.value.OvDtId = medicamentoSeleccionado.value.OvDtId
  modelApartado.value.Cantidad = parseInt(medicamentoSeleccionado.value.OpenCreQty)
}
const setMedicamentoEntradaMercancia = () => {
  modelApartado.value.ItemCode = medicamentoSeleccionadoDeEntrada.value.CodMx
  modelApartado.value.Cantidad = medicamentoSeleccionado.value.OpenCreQty > parseInt(medicamentoSeleccionadoDeEntrada.value.Quantity) ? parseInt(medicamentoSeleccionadoDeEntrada.value.Quantity) : medicamentoSeleccionado.value.OpenCreQty
  modelApartado.value.IdItemCode = medicamentoSeleccionadoDeEntrada.value.IdItemCode
  modelApartado.value.CodBod = medicamentoSeleccionadoDeEntrada.value.WhsCode
}
const obtenerEntradasMercancia = () => {
  _ApartadoService.value.getEntradasMercancia().then(({ data }) => {
    entradasMercancia.value = data
  })
}
const buscarMedicamentosEnStock = () => {
  _ApartadoService.value.buscarMedicamentosEnStock({
    ItemCode: medicamentoSeleccionadoDeEntrada.value
  }).then(({ data }) => {
    medicamentosDeEntrada.value = data
  })
}
const editar = async (id) => {
  _ApartadoService.value.find(id).then((apartado) => {
    modelApartado.value = {
      OvId: apartado.data.OvId,
      OvDtId: apartado.data.OvDtId,
      Cantidad: apartado.data.Cantidad,
      ItemCode: apartado.data.ItemCode,
      Id: apartado.data.Id
    }
    _ApartadoService.value.getOv(apartado.data.OvId).then((med) => {
      medicamentos.value = med.data
      medicamentoSeleccionado.value = medicamentos.value.find(a => a.OvDtId === apartado.data.OvDtId)
      medicamentoSeleccionado.value.OpenCreQty = parseInt(medicamentoSeleccionado.value.OpenCreQty) + parseInt(apartado.data.Cantidad)
      _ApartadoService.value.buscarMedicamentosEnStock({
        ItemCode: apartado.data.ItemCode
      }).then(({ data }) => {
        medicamentosDeEntrada.value = data
        medicamentoSeleccionadoDeEntrada.value = medicamentosDeEntrada.value.find(a => a.CodMx === apartado.data.ItemCode)
      })
    })
  })
  displayCrearApartado.value = true
}
const eliminar = (id, medicamento, orden) => {
  return Swal.fire({
    title: 'Advertencia',
    icon: 'warning',
    text: `¿Esta seguro de eliminar el apartado del medicamento ${medicamento} de la orden ${orden}?`,
    showCancelButton: true,
    confirmButtonText: 'Eliminar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: '#dc3545'
  }).then((result) => {
    if (result.isConfirmed) {
      return _ApartadoService.value.eliminar(id).then(async () => {
        await listar()
        toast.add({ severity: 'success', summary: 'Éxito', detail: 'Apartado eliminado con éxito', life: 3000 })
      })
    }
  })
}
const agregarPreliminar = () => {
  if (!modelApartado.value.Cantidad) return toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debes ingresar una cantidad mayor a 0.', life: 3000 })
  pendientesPorApartar.value.push({
    ...medicamentoSeleccionadoDeEntrada.value,
    ...modelApartado.value
  })
  medicamentoSeleccionado.value = {}
  modelApartado.value.Cantidad = 0
  modelApartado.value.OvId = null
}
const abrirModalAbrirApartado = () => {
  displayCrearApartado.value = true
  medicamentoSeleccionadoDeEntrada.value = null
  modelApartado.value = {
    OvId: null,
    OvDtId: null,
    Cantidad: 0,
    ItemCode: '',
    IdItemCode: 0,
    CodBod: ''
  }
  pendientesPorApartar.value = []
}
onMounted(() => {
  listar()
  obtenerEntradasMercancia()
})

</script>
<style lang="scss">
::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
::-webkit-scrollbar {
  width: 0.4rem;
}
.p-selectable-row:hover {
  .idHijo{
    color: blue
  }
  .idHijoEliminar{
    color: red
  }
}
</style>
